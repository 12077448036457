@import "vars";
@import "mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


// New Pro Offer Page
/////////////////////

.container-100 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  .row {
    div[class^='col'] {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 200px;
    }
  }
}
.p-md-8 {
  @include media-breakpoint-up(lg) {
    padding: 8rem !important;
  }
  @include media-breakpoint-down(lg) {
    padding: 2rem !important;
  }
}

.ml-less-15 {
  margin-left: -15px;
  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}

#pro-header {

  background-color: $pro-blue;
  img.logo-full {
    height: 60px;
    @include media-breakpoint-down(sm) {
      height: 40px;
    }
  }

  @include media-breakpoint-down(sm) {
    .navbar {
      min-width: 0;
      flex-grow: 1;
      @include media-breakpoint-up(lg) {
        padding: 0;
      }
    }

    .nav-link {
      color: $black;
      &.nav-link-highlight {
        display: block;
        @include media-breakpoint-up(lg) {
          color: $black;
          border: 2px solid $testimony-blue;
          border-radius: 6px;
          margin-top: 2px;
          &:hover {
            color: $white !important;
            background: $testimony-blue !important;
          }
        }
      }
    }

    li .nav-link:hover {
      color: $testimony-blue;
    }
    .navbar-toggler {
      background-color: $white;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0 0 0 10px;
      padding: 15px 20px;
      color: $black;
      text-transform: uppercase;
      font-size: .7rem;
    }
    .navbar-collapse {
      background-color: white;
      box-shadow: 0 6px 10px #0000004D;
      padding: 0 5%;
      border-radius: 15px;
      text-align: center;
      position: absolute;
      width: 100%;
      right: 0;
      left: 0;
      top: calc(12%);
      z-index: 100;
    }
  }
  .circle-img {
    border-radius: 50%;
    width: 23px;
    margin-right: .5rem;
  }
  h1 {
    color: $pro-blue;
  }

  .navbar-brand {
    margin: .5rem;
  }
}

.btn-light {
  font-weight: 600 !important;
  letter-spacing: 0;
}

@include media-breakpoint-up(md) {
  .back-to-home:hover span {
    display: none;
  }
  .back-to-home:hover:before {
    content: "Revenir à aladom";
  }
}

.back-to-home:hover {
  color: $white;
  background-color: $azure-blue;
  border: 1px solid $azure-blue;
}
.pro-navbar-full {
  background-color: $gray-300;
}
#pro-navbar {
  font-weight: 600 !important;
  .dropdown-menu {
    margin-top: -0.5rem;
    border: inherit;
    border-radius: inherit;
    background-color: inherit;
    padding: .5rem 0 0 0;
    li {
      height: 100%;
    }
    .dropdown-item {
      background-color: $gray-300;
      @include media-breakpoint-down(sm) {
        font-size: .7rem;
      }
      &:hover {
        background-color: $pro-blue;
        color: $white;
        transition: all .5s linear;
        width: 90%;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    font-size: .7rem;
  }
  @media (max-width: 400px) {
    font-size: .6rem;
  }
}

.pro-offers {
  color: $black;
  #product-premium h3 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .ala-3x.ala-envelope {
    font-size: 2.5rem;
  }

  .circle-img {
    border-radius: 50%;
  }

  h2, h1 {
    padding-bottom : 2rem;
    font-size: 2.5rem;
    @include media-breakpoint-down(xs){
     font-size : 1.5rem;
    }
  }

  h3 {
    font-size: 1.375rem;
    font-weight: 500;
  }

  p, a {
    letter-spacing: -.4px;
    border: none;
    line-height: 1.75rem;
    @include media-breakpoint-down(xs){
     font-size : 1rem;
    }
  }

  ol, li {
    list-style-type: none;
  }

  b {
    color: $black;
  }

  h1.pro-home-title {
    margin-top: 2rem;
    color: inherit !important;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 800;
    text-transform: uppercase;
    @include media-breakpoint-down(xs) {
      margin-left: 5px;
      font-size: 1.25rem;
    }
  }
  div.pro-label {
    margin: 0 2rem;
    font-size: 1.25rem;
    font-weight: 500;
    @include media-breakpoint-down(xs) {
      font-size: 0.9rem;
    }
  }
  div.shadow-card {
    padding: 10px;
    box-shadow: 0 10px 30px #00000029;
    border: inherit;
    margin-bottom: 2rem;
    .home-icon, .collectivity-icon, .enterprise-icon {
      margin: 1rem 0 -2rem 2rem;
      width: 60px;
      height: 60px;
      @include media-breakpoint-down(lg) {
        margin: auto;
      }
    }
    .home-color, .collectivity-color, .enterprise-color {
      font: normal normal 800 26px/30px Montserrat;
    }
    .aladom-card-on-image {
      font: normal normal 800 35px/30px Montserrat;
      letter-spacing: 0;
      color: $black;
    }
  }
  h1.home-title {
    font: normal normal 600 50px/50px Montserrat;
    letter-spacing: 0;
    padding: 1rem 4rem;
    color: $black;
    text-transform: uppercase;
  }
  .recruiter-box {
    max-width: 700px;
    margin: auto;
    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    h2, p {
      text-align: center;
    }
  }

  .btn-pro {
    margin: 1rem;
    border-radius: 50px;
    text-align: center;
    color: $white !important;
  }

  .pro-blue-bloc, .pro-white-bloc {
    background-color: $pro-blue;
    color: $white;
    width: 100%;
    position: relative;
    padding: 6rem 2rem 6rem 3rem;
    @include media-breakpoint-down(xs) {
      padding: 1rem;
    }

    h2 {
      font-size: 1.3rem;
      padding: 6rem 0;
      font: normal normal bold 25px/40px Montserrat;
      color: #FFFFFF;
      text-transform: uppercase;
      letter-spacing: 0;
    }
    h1 {
      font-size: 1.5rem;
      padding: 4rem 2rem;
      text-transform: uppercase;
      color: #FFFFFF;
      font-weight: inherit;
    }
    p {
      padding-left: 0;
      padding-bottom: 2rem;
      font: normal normal 300 25px/30px Montserrat;
      letter-spacing: 0;
    }
    &.with-no-padding-bottom {
      padding: 0;
      margin-bottom: 4rem;
    }
    &.max-width {
      margin: auto;
      max-width: 1000px;
    }
    div {
      font: normal normal 300 25px/30px Montserrat;
      letter-spacing: 0;
      color: #FFFFFF;
    }
  }
  .pro-blue-bloc.minimal-font, .pro-white-bloc.minimal-font {
    .center-on-pro-blue-bloc, .center-on-pro-white-bloc {
      p, div {
        font: normal normal 300 20px/30px Montserrat;
      }
      a {
        margin: 7rem auto;
      }
      @include media-breakpoint-down(md) {
        padding-left: 1rem;
      }
    }
  }

  .default-box {
    border: 1px solid $pro-blue;
    max-width: 600px;
    margin: 3rem auto;
    padding: 2rem;
    color: $black;
    font-size: 1.1rem;
  }
  .pole-emploi-box {
    border: 1px solid #000000;
    border-radius: 20px;
    max-width: 600px;
    margin: 3rem auto;
    padding: 2rem;
    color: $black;
    font-size: 1.1rem;
    img {
      margin-top: -6rem;
      max-width: 250px;
      margin-bottom: 2rem;
      text-align: center;
    }
    .font-bold {
      font-weight: bold;margin-bottom: 3rem;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding: unset;
    }
  }

  .pro-blue-color {
    color: $pro-blue;
  }
  .pricing-box {
    background: transparent linear-gradient(147deg, #2653FB 0%, #132A7E 100%);
    color: $white;
    padding: 1rem;
    min-width: 300px;
    height: 300px;
    display: table-cell;
    vertical-align: middle;
    box-shadow: rgb(0 0 0 / 25%) 0 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
    @include media-breakpoint-down(md) {
      display: inherit;
      max-height: 300px;
      padding-top: 7rem;
    }
    p {
      color: white;
      text-align: center;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
  }
  #lead-pricing {
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .pricing-text {
    ul {
      li {
        list-style-type: initial;
      }
    }
    p {
      font-size: 1.1rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
  }

  a.btn.large-btn {
    padding: 1rem;
    font-weight: inherit !important;
  }
  .btn-square {
    border-radius: 0;
  }
  .big-price {
    font-size: 2rem;
  }
  .lead-cards {
    margin: -22.5rem 0 26rem;
    padding: 2rem;
    @include media-breakpoint-down(xs){
      padding: 12rem 0;
      margin: -13.5rem 0 5rem;
    }
    .lead-card {
      font-size: 1rem;
      color: $pro-blue;
      box-shadow: rgb(0 0 0 / 25%) 0 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
      width: 100%;
      height: calc(100% - 188px);
      border-radius: 19px;
      .lead-card-header {
        background-color: $pro-blue;
        color: $white;
        text-transform: uppercase;
        font-size: 1.8rem;
        padding: 3rem;
        border-radius: 19px;
        h2 {
          color: $white;
          text-transform: uppercase;
          font-size: 1.8rem;
          padding: 0;
          margin: 0;
        }
        @include media-breakpoint-down(lg){
          font-size: 1.25rem;
          padding: 2rem;
        }
      }
      .lead-card-body {
        .lead-price {
          font-size: 2rem;
          text-align: center;
          margin: 2rem 0;
          font-weight: 700;
        }
        p {
          color: $pro-blue;
          font-size: 1.2rem;
        }
        hr {
          background-color: $pro-blue;
          height: 2px;
        }
        div {
          color: $pro-blue;
        }
      }
    }

  }
  .img-guide-client-cases {
    @include media-breakpoint-down(lg) {
      max-width: 250px;
      text-align: center;
      margin: auto;
    }
  }
  .vertical-center-text {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .black-font {
    font-size: 3rem !important;
    letter-spacing: 0;
    color: $black;
  }
  form.plezi-form {
    max-width: 260px;
  }
  #product-premium {
    div.col-md-6.text-center {
      img:not(.follow-up-img) {
        width: 100%;
      }
    }
    // text
    div.col-md-6.m-auto.text-center {
      padding: 4rem;
    }
    // image
    div.col-md-6.m-auto:not(.text-center) {
      padding: 4rem;
    }
  }
  .pro-word-cloud-box {
    &-title {
      font-weight: 800;
      font-size: 1.25rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    @include media-breakpoint-down(md) {
      div.pro-word {
        font: unset;
      }
    }
  }
  .pro-cloud-title {
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 3rem;
    }
  }
}
img.follow-up-img {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.big-h2 {
  text-transform: uppercase;
  font: normal normal 600 50px/50px Montserrat;
  letter-spacing: 0;
  color: $black;
  font-size: 2rem;
  font-weight:800;
}

.resources-choices {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto;
  column-gap: 2rem;
  @include media-breakpoint-down(lg) {
    grid-template-columns: 50% 50%;
    row-gap: 1.5rem;
  }
  @include media-breakpoint-down(sm) {
    column-gap: unset;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.testimony-title {
  h1 {
    padding-bottom: unset;
    color: $unbounce-blue;
  }
}
.resources-box {
  border: 3px solid #001836;
  border-radius: 30px;
  background-color: $white;
  padding: 1rem;
  width: 100%;
  text-align: center;
  height: 200px;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  box-shadow: inset 0 0 0 0 #001836, 10px 5px 0 0 #001836, 10px 10px 0 0 #001836;
  transition: box-shadow 1.5s ease, transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease;
  -webkit-transition: box-shadow 1.5s ease, transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease;
  -moz-transition: box-shadow 1.5s ease, transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease;
  -o-transition: box-shadow 1.5s ease, transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease;
  @include media-breakpoint-down(md) {
    height: 160px;
  }
  @include media-breakpoint-down(sm) {
    height: 140px;
    img {
      height: 60px;
      width: fit-content;
    }
  }
  p {
    color: $testimony-blue;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: unset !important;
    font-weight: 600;
    font-size: 1.5rem;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      line-height: normal;
    }
  }
  &.resources-selected {
    border: 3px solid #2653FB;
    box-shadow: none;
    -webkit-transform: translate(10px,10px);
    -ms-transform: translate(10px,10px);
    transform: translate(10px,10px);
  }
  &:not(.resources-selected):hover {
    -webkit-transform: translate(10px, 10px);
    -ms-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
    box-shadow: none;
    transition: box-shadow 1s ease, transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease;
    -webkit-transition: box-shadow 1s ease, transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease;
    -moz-transition: box-shadow 1s ease, transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease;
    -o-transition: box-shadow 1s ease, transform 1s ease, -webkit-transform 1s ease, -ms-transform 1s ease;
  }
}
.new-clients {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 20px;
  padding: 2rem;
  margin-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    height: 90%;
  }
  @include media-breakpoint-down(md) {
    text-align: center;
    height: 95%;
  }
  h2 {
    color: #001836;
    font-size: 1rem;
  }
  p {
    padding-left: unset;
    line-height: unset;
    font-size: .875rem;
  }
  span[data-atc] {
    padding: 0.2rem 1.5rem;
    color: $white !important;
    cursor: pointer;
    &:hover {
      background: $white !important;
      color: $testimony-blue !important;
    }
  }
}
.testimonies {
  margin-bottom: 250px;
}
.brand-type-choices, .testimonies-type-choices {
  border-radius: 50px;
  text-align: center;
  margin: auto;
  text-transform: uppercase;
  background: lightgray;
  border: 1px solid $black;
  max-width: 400px;
  cursor: pointer;
  span {
    &.select-SAP {
      border-radius: 50px 0 0 50px;
    }
    &.select-EHPAD {
      border-radius: 0 50px 50px 0;
    }
    padding: 1rem;
    &.brand-selected {
      background: $black;
      color: $white;
      font-weight: bold;
    }
    span {
      margin: auto;
    }
  }
}
.testimonies-type-choices {
  border: 1px solid $white;
  background: $black;
  span {
    &.brand-selected {
      background: $white;
      color: $black;
      font-weight: bold;
    }
  }
}
.testimony-info {
  margin-top: 3rem;
  @include media-breakpoint-down(xs) {
    margin-top: 1rem;
  }
}
.client-testimony-text {
  line-height: 2rem;
  font-weight: 200;
  @include media-breakpoint-down(xs) {
    font-size : 0.8rem;
  }
}
.brand-cards {
  min-height: 200px;
  margin-bottom: 200px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}
.brand-card {
  width: 250px;
  height: 250px;
  margin: 2rem 2rem 0 2rem;
  box-shadow: 0 0 20px #becbfa;
  background-color: white;
  transition: .8s;
  &:nth-child(5n) {
    margin-right: 2rem;
  }
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: black;
  z-index: 1;
  @include media-breakpoint-down(sm) {
    width: 200px;
    height: 200px;
    margin-left: 2rem;
  }

  span {
    text-align: left;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3rem;
    text-transform: uppercase;
    line-height: 22px;
  }

  &:hover {
    box-shadow: inset 0 0 10px #2653FB;
    color: #2653FB;
  }
}
.background-img, .recruit-background-img, .lead-background-img {
  flex-direction: column;
  justify-content: start;
  background-image: url('/ui/static/img/home_header_img.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  overflow: visible;
  min-height: 900px;
  @include media-breakpoint-down(xs){
   min-height: 600px;
  }
  max-height: unset;
  margin-bottom: 150px;
  padding: 6rem 0;
}
#premium-offers {
  .recruit-background-img {
    margin-bottom: 12.5rem;
    @include media-breakpoint-down(sm){
      margin-bottom: 0;
    }
  }
  ul {
    padding: 0;
  }
}

.recruit-background-img, .lead-background-img {
  background-image: url('/ui/static/img/pro-offers/aladom-recruteur.jpg');
  opacity: 0.85;
  margin-bottom: unset;
  h1 {
    color: $white;
    padding-top: 5rem;
    font: normal normal 800 40px/40px Montserrat;
    @include media-breakpoint-down(xs){
      font-size: 1.5rem;
    }
    letter-spacing: 0;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  div.prem-sentence, div.lead-sentence {
    font-size: 2rem;
    @include media-breakpoint-down(xs){
      font-size: 1.1rem;
    }
    color: $white;
  }
}
.prem-info {
  margin-top: 4rem;
  p {
    color: $white;
    margin-bottom: .5rem;
    padding-bottom: 0;
    font-size: 1rem;
  }
}
.different-offers {
  padding: 1rem 2rem;
  margin-top: -25rem;
  i.ala-structure, i.ala-manage-candidacies, i.profils {
    font-size: 3rem;
  }
  @include media-breakpoint-down(md) {
    margin-top: -10rem;
    i.ala-structure, i.ala-manage-candidacies, i.profils {
      font-size: 2.5rem;
    }
  }
  @include media-breakpoint-down(xs) {
    margin-top: -2rem;
    i.ala-structure, i.ala-manage-candidacies, i.profils {
      font-size: 1.5rem;
    }
  }

  div.card {
    a.btn-pro-offer {
      margin: 2rem;
    }
    @include media-breakpoint-down(sm) {
      &.card-right {
        margin-top: 3rem;
      }
    }
    height: 100%;
    border: unset;
    background: #EAEFF5;
    box-shadow: 0 3px 50px #000000A6;
    h2 {
      font: italic normal bold 30px/37px Montserrat;
      color: #001836;
      margin: 2rem 0 0 3rem;
    }
    padding: 0;
    p {
      margin-left: 3rem;
      font-size: 1rem;
    }
    .black-bloc, .essentiel-bloc {
      p {
        color: $white;
      }
      background: #001836;
      h2 {
        color: $white;
      }
    }
    .essentiel-bloc {
      padding: 2rem;
      p {
        margin: auto;
        text-align: center;
        font-size: 1rem;
        b {
          text-transform: uppercase;
          color: $white;
        }
      }
    }
    .white-bloc {
      background: #EAEFF5;
      padding: 2rem;
      h3, h2, p {
        color: #001836;
      }
      h3 {
        font-size: 1rem;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    &.card-right {
      .white-bloc {
        padding-top: 0;
      }
    }
  }
  .perf-and-more {
    margin-top: -4.8rem;
    font-size: 6rem;
    text-align: center;
    color: $testimony-blue;
  }
}
.premium-price {
  margin-top: 150px;
  margin-bottom: 200px;

  .col-12 {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
  h2 {
    color: #000000;
    text-align: center;
  }
  .essentiel-title {
    font: italic normal bold 20px/36px Montserrat;
    color: #4CC2F1;
    @include media-breakpoint-down(sm) {
      font: italic normal bold 14px/36px Montserrat;
    }
  }
  .performance-title {
    font: italic normal bold 20px/36px Montserrat;
    color: #4269FC;
    @include media-breakpoint-down(sm) {
      font: italic normal bold 14px/36px Montserrat;
    }
  }
  th.blue-top-border {
    border-top: 6px solid #4CC2F1;
    border-left: 6px solid #4CC2F1;
    border-right: 6px solid #4CC2F1;
    border-radius: 28px 28px 0 0;
  }
  td.blue-side-border {
    border-left: 6px solid #4CC2F1;
    border-right: 6px solid #4CC2F1;
  }
  td.blue-bottom-border {
    border-left: 6px solid #4CC2F1;
    border-right: 6px solid #4CC2F1;
    border-bottom: 6px solid #4CC2F1;
    border-radius: 0 0 28px 28px;
  }
  th.purple-top-border {
    border-top: 6px solid $testimony-blue;
    border-left: 6px solid $testimony-blue;
    border-right: 6px solid $testimony-blue;
    border-radius: 28px 28px 0 0;
  }
  td.purple-side-border {
    border-left: 6px solid $testimony-blue;
    border-right: 6px solid $testimony-blue;
  }
  td.purple-bottom-border {
    border-left: 6px solid $testimony-blue;
    border-right: 6px solid $testimony-blue;
    border-bottom: 6px solid $testimony-blue;
    border-radius: 0 0 28px 28px;
  }
  tr.blue-tr {
    background: $white !important;
    td {
      background: #021033;
      border: 1px solid #F8F9FF;
      color: $white;
    }
    td.option {
      padding: 0;
      border: 1px solid #021033;
      background: $white;
      transform: matrix(0,-1,1,0,47,27);
      text-align: center;
      font: italic normal bold 16px/20px Montserrat;
      color: #021033;
      span {
        padding: .75rem;
        text-transform: uppercase;
      }
      @include media-breakpoint-down(lg) {
        padding: 6px;
      }
      @include media-breakpoint-down(md) {
        padding: 6px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .hide-under-md {
      display: none;
    }
    td, th {
      padding: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    td, th {
      font-size: .6rem;
    }
  }
}
.option-legend {
  margin-bottom: 2rem;
  margin-left: 1.2rem;
  @include media-breakpoint-up(lg) {
   display: none;
  }
  span.option-bloc {
    background: #021033;
    border: 1px solid #FFFFFF;
    width: 50px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  span.option-text {
    font: italic normal 600 10px/11px Montserrat;
    color: #021033;
    vertical-align: middle;
  }
}
.lead-background-img {
  background-image: url('/ui/static/img/pro-offers/aladom-leads.jpg');
  h1 {
    @include media-breakpoint-down(sm) {
      padding-top: 1rem;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 0;
      margin-bottom: 0;
    }
  }
  div.lead-question {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 600;
    color: $white;
  }
  div.lead-answer {
    font-size: 1.25rem;
    color: $white;
  }
  div.lead-sentence {
    font-size: 2rem;
    color: $white;
  }
  @include media-breakpoint-down(sm) {
    div.lead-answer {
      font-size: 1rem;
    }
    div.lead-sentence {
      font-size: 1rem;
    }
    div.lead-question {
      margin-top: 0.5rem;
      font-size: 1.25rem;

    }
  }
}
.medium-font {
  p {
    font-size:1.25rem;
    @include media-breakpoint-down(sm) {
      ffont-size:1.25rem;
    }
  }
}
.recruiter-bloc {
  padding: 8rem 10rem;
  background: $testimony-blue;
  min-height: 400px;
  margin-top: -500px;
  @include media-breakpoint-down(sm) {
    margin-top: -200px;
    padding: 3rem;
  }
  @include media-breakpoint-down(md) {
    padding: 8rem 2rem;
  }
  color: $white;
  &-text {
    font-weight: 200;
    font-size: 1.25rem;
    @include media-breakpoint-down(xs){
      font-size : 0.8rem;
    }
  }
  h2 {
    color: $white;
    font-size: 2.5rem;
    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;
    }
    font-weight: 800;
    text-transform: uppercase;
  }
  p {
    width: 60%;
    font-size : 1.25rem;
    @include media-breakpoint-down (xs) {
      width:100%;
      font-size : 1rem
    }
  }
}
.font-size-md {
  font-size: 2rem;
  @include media-breakpoint-down(xs) {
    font-size : 1rem;
  }
}
.client-h2, .pricing-h2, .demo-bloc-h2 {
  font-size: 2.5rem !important;
  font-weight: 800;
  @include media-breakpoint-down(xs) {
    font-size : 1.5rem;
  }
}

#pro-wave {
  background-image: url('/ui/static/img/pro-offers/pro-wave.svg');
  background-size: cover;
  color: $white;
  min-height: 600px;
  margin-bottom: 6rem;
  padding: 1rem;
  width: 100%;
  @include media-breakpoint-down(xs) {
    background: #021736;
  }
  h2, {
    font-size: 2.5rem;
    @include media-breakpoint-down(xs){
     font-size : 1.5rem;
    }
    color: $white;
  }
  h3, p {
    color: $white;
  }
  h2 {
    padding-top: 20rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }
  hr {
    background: $white;
  }
}
.pricing-card {
  box-shadow: 0 5px 20px rgb(0 0 0 / 45%);
  padding: 2rem;
  background-color: #fff;
  margin-bottom: 1rem;
}
a.btn-pro-blue {
  background-color: #001836;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 10px;
}
.lead-bloc {
  background: $pro-blue;
  min-height: 400px;
  color: $white;
  padding-top: 6rem;
  &-text {
    font-weight: 200;
    font-size: 1.25rem;
    @include media-breakpoint-down(xs){
      font-size : 0.8rem;
    }
  }
  h2 {
    color: $white;
    font-size: 2.5rem;
    font-weight: 800;
    text-transform: uppercase;
    @include media-breakpoint-down(xs){
      font-size : 1.5rem;
    }
  }
  p {
    width: 60%;
    font-size : 1.25rem;
    @include media-breakpoint-down(xs){
      font-size : 1rem;
      width:110%;
    }
  }
}
.lead-bloc-content {
  padding: 8rem 10rem;
  @include media-breakpoint-down(md) {
    padding: 8rem 6rem;
  }
  @include media-breakpoint-down(sm){
    padding: 8rem 2rem;
  }
}
.demo-bloc, .pricing-bloc, .client-bloc {
  padding: 6rem;
  text-align: center;
  min-height: 400px;
  color: $white;
  @include media-breakpoint-down(xs){
      padding: 2rem;
    }
  h2 {
    font-size: 2.5rem;
    font-weight: 800;
    @include media-breakpoint-down(xs){
      font-size: 2rem;
      margin-top: 4rem;
    }
  }
  p {
    text-align: center;
    font-size: 2rem;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight:200;
    @include media-breakpoint-down(xs){
      font-size: 1rem;
    }
  }
}

.demo-bloc, .pricing-bloc {
  h2 {
    color: $white;
  }
  color: $white;
}

.client-bloc {
  h2{
    color: $black;
  }
  color: $black;
}

.demo-bloc {
  background: $pro-blue;
}
.pricing-bloc {
  background: #2653FB;
}

.lead-banner {
  border-radius: 0 12px 12px 0;
  background: #707071;
  color: $white;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 1rem;
  width: fit-content;
}
.blue-recruit-banner {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  a.col-4 {
    z-index: 1000;
    box-shadow: 0 3px 6px #00000029;
    text-align: center;
    padding: 1rem;
    color: $testimony-blue !important;
    background: $white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    img {
      margin-top: -0.5rem;
    }
    &:hover {
      text-decoration: none;
    }
    &.is-selected {
      background: $testimony-blue;
      color: $white !important;
    }
  }
}
.text-link-bloc {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.structure-link {
  div.logo-link {
    background: url('/ui/static/img/pro-offers/filters/blue/structure.svg') right / contain no-repeat;
    width: 47px;
    height: 100%;
  }
  &.is-selected {
    div.logo-link {
      background: url('/ui/static/img/pro-offers/filters/white/structure.svg') right / contain no-repeat;
    }
  }
}
.profils-link {
  div.logo-link {
    background: url('/ui/static/img/pro-offers/filters/blue/profils.svg') right / contain no-repeat;
    width: 47px;
    height: 100%;
  }
  &.is-selected {
    div.logo-link {
      background: url('/ui/static/img/pro-offers/filters/white/profils.svg') right / contain no-repeat;
    }
  }
}
.candidacy-link {
  div.logo-link {
    background: url('/ui/static/img/pro-offers/filters/blue/candidacy.svg') right / contain no-repeat;
    width: 47px;
    height: 100%;
  }
  &.is-selected {
    div.logo-link {
      background: url('/ui/static/img/pro-offers/filters/white/candidacy.svg') right / contain no-repeat;
    }
  }
}
#structure-bloc, #profils-bloc, #candidacy-bloc {
  background: $testimony-blue;
  color: $white;
  padding: 2rem;
  img {
    width: 80px;
    margin-top: 3rem;
  }
  h2 {
    margin-top: 2rem;
    font-size: 2rem;
    @include media-breakpoint-down(xs){
      font-size: 1.5rem;
      margin-bottom: 30px;
    }
    color: $white;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  p {
    font-size: 1.2rem;
  }
  ul {
    margin-top: 2rem;
    margin-left: 30%;
    @include media-breakpoint-down(sm) {
      margin-left: unset;
    }
  }
}
#profils-bloc, #candidacy-bloc {
  margin-top: -170px;
  padding-top: 180px;
  ul {
    margin-left: 40%;
    @include media-breakpoint-down(sm) {
      margin-left: unset;
    }
  }
  box-shadow: 0 3px 6px #00000029;
}
.sap-bloc, .elipse-bloc {
  padding-top: 3rem;
  position: relative;
  clip-path: ellipse(170% 100% at 50% 0);
  -webkit-clip-path: ellipse(170% 100% at 50% 0);
  @include media-breakpoint-down(xs){
    clip-path: ellipse(300% 100% at 50% 0);
    -webkit-clip-path: ellipse(300% 100% at 50% 0);
  }
}
.sap-bloc {
  background: #EBECEF 0 0 no-repeat padding-box;
}
.elipse-bloc {
  background: #FFFFFF 0 0 no-repeat padding-box;
}
.font-weight-600 {
  font-weight: 600;
}

.satisfaction-bloc {
  padding-top: 5rem;
  padding-bottom: 5rem;
  @include media-breakpoint-down(xs){
    padding: 4rem 2rem 2rem;
    .row {
      & > div:not(:first-child) {
        margin-top: 10rem;
      }
    }
  }

  h2 {
    color: $white;
    font-size: 2rem;
  }
  background: $pro-blue;
  min-height: 400px;
  color: $white;
}
.euro-bloc {
  padding: 4rem 2rem 2rem 2rem;
  margin-top: 100px;
  margin-bottom: 100px;
  .euro {
    text-align: center;
    font-size:15rem;
    font-weight: 700;
    @include media-breakpoint-down(xs) {
      font-size: 6.25rem;
    }
    letter-spacing: 0;
    color: #001836;
  }
  h2 {
    text-transform: uppercase;
    color: $black;
    font-size: 2rem;
    font-weight: 800;
  }
}
.btn-pro-offer {
  margin-top: 2rem;
  padding: 1rem 3rem;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border-radius: 92px;
  text-align: center;
  font: normal normal medium 20px/24px Montserrat;
  color: $testimony-blue;
  text-transform: uppercase;
  background: $white;
  font-weight: 500;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  &.dark-blue-btn {
    background: $pro-blue;
    color: $white;
    border:2px solid $pro-blue;
  }
  &.light-blue-btn {
    background: $testimony-blue;
    color: $white;
    border:2px solid $testimony-blue;
  }
   &.white-btn {
    background: $white;
    color: $testimony-blue;
    border:2px solid $testimony-blue;
   }
  &.light-blue-white-background-btn {
    background: $testimony-blue;
    color: $white;
    border: 2px solid $testimony-blue;
    &:hover {
      text-decoration: none;
      border: 2px solid $testimony-blue;
      background-color: transparent;
      color: $testimony-blue
    }
  }
  &:hover {
    text-decoration: none;
    border:2px solid $white;
    color: $white;
    background-color: transparent;
  }
  @include media-breakpoint-down(xs) {
    display: inline-table;
  }
}
.large-btn {
  height: 78px;
  padding: 1.5rem 3rem;
}
.float-button, .blue-float-button {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: black;
  border-radius: 92px;
  text-align: center;
  font-size: 1rem;
  color: $white;
  font-weight: 400;
  text-decoration: none;
  padding: .8rem 2rem;
  z-index: 10000;
  min-width: 230px;
    &:hover {
      text-decoration: none;
      color: #c4dce8;
    }
}
a.blue-float-button {
  bottom: 80px;
  background: $testimony-blue;
  border: 2px solid $white;
}
.rounded-lead-category {
  margin-top: 3rem;
  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }
  @include media-breakpoint-down(xs) {
    margin-top: 3rem;
    width: 100%;
  }
  div.white-rounded {
    width: 66px;
    height: 66px;
    background: $white;
    border-radius: 50%;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    padding-top: 1rem;
    i {
      font-size: 2rem;
    }
  }
  div.col-9 {
    margin: auto;
    p {
      color: $white;
      margin: auto;
      font-size: 1rem;
      letter-spacing: 0;
      text-transform: lowercase;
      margin-left: 1rem;
    }
    @include media-breakpoint-down(md) {
     display: none;
    }
  }
}
.slider-brands {
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }

  .slide-brand {
    display: flex;
    flex-shrink: 0;
  }
}
.btn-info-bloc {
  @include media-breakpoint-down(sm) {
    a.btn-pro-offer {
      display: block;
    }
  }
}
.square-card {
  width: 150px;
  height: 150px;
  @include media-breakpoint-down(xs) {
    width: 100px;
    height: 100px;
  }
  margin: 1rem;
  box-shadow: 0 4px 12px #0000004D;
  border-radius: 24px;
  background-color: white;
  transition: .8s;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;

  &:hover {
    box-shadow: inset 0 0 10px #2653FB;
    color: #2653FB;
  }
  img {
    margin: auto;
  }
}

.img-resources {
  width: 130px;
  text-align: center;
  margin: auto;
  @include media-breakpoint-down(lg) {
    width: 120px;
  }
  @include media-breakpoint-down(md) {
    width: 100px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}


.column-name{
  @include media-breakpoint-down(md){
    display:None;
  }
}
.invoices{
  min-width: 100px;
}
//////////////////

// Yousign
//////////
#iframe-container {
  width: 750px;
  height: 800px;
}

#iframe-container iframe {
  width: 100%;
  height: 100%;
}